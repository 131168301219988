<template>
  <div class="message_box">
    <!-- 我的消息 -->
    <app-container :title="'我的消息'" :background="tool.getThemeList(theme)">
      <div
        class="both_content"
        :class="{
          empty_content: list.length == 0
        }"
      >
        <div v-if="list.length > 0">
          <div
            class="desc_message"
            :key="index"
            v-for="(item, index) in list"
            @click="toComponent(item.receiver ? 'notice' : 'notice_', item)"
          >
            <div class="desc_logo">
              <img
                :src="
                  require(`_ASSETS_/image/cardManagement/${
                    item.receiver ? 'icon_mymessage_a' : 'icon_mymessage_b'
                  }.png`)
                "
                alt=""
              />
            </div>
            <div class="desc_text">
              <div>
                {{ item.title }}
                <van-tag :type="item.isRead != 0 ? 'success' : 'danger'">{{
                  item.isRead != 0 ? "已读" : "未读"
                }}</van-tag>
              </div>
              <div class="desc_step">
                {{ item.content.replace(/(^\s*)|(\s*$)/g, "") }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty_list">
          没有更多了~~~
        </div>
      </div>
    </app-container>
  </div>
</template>

<script>
import {
  msgNoticeList,
  msgInformList,
  msgInformInfo,
  msgNoticeInfo
} from "_API_/api.services";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
export default {
  setup() {
    const state = reactive({
      card: "", //卡号
      page: 1, //页数
      limit: 30, //条数
      list: [], //通知信息 && 公告信息的数据源
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    const methods = {
      //路由跳转
      toComponent(type, item) {
        switch (type) {
          case "notice":
            msgInformInfo({
              msgInformId: item.id,
              card: state.card
            }).then(({ data: res }) => {
              router.push({
                name: "message-notice",
                query: res.msgInform
              });
            });
            break;
          case "notice_":
            msgNoticeInfo({
              msgNoticeId: item.id,
              card: state.card
            }).then(({ data: res }) => {
              router.push({
                name: "message-notice",
                query: item
              });
            });

          default:
            break;
        }
      },
      //通知列表
      getMsgInformList() {
        return new Promise(resolve => {
          msgInformList({
            page: state.page,
            limit: state.limit,
            card: state.card
          }).then(({ data: res }) => {
            if (res.code == 0) {
              resolve(res);
              return;
            }
            tool.toast({
              msg: res.msg,
              duration: 1500
            });
          });
        });
      },
      //公告列表
      getMsgNoticeList() {
        return new Promise(resolve => {
          msgNoticeList({
            page: state.page,
            limit: state.limit,
            card: state.card
          }).then(({ data: res }) => {
            if (res.code == 0) {
              resolve(res);
              return;
            }
            tool.toast({
              msg: res.msg,
              duration: 1500
            });
          });
        });
      },
      // 获取通知列表
      getData() {
        Promise.all([ctx.getMsgInformList(), ctx.getMsgNoticeList()]).then(
          values => {
            let arr = [],
              arr_ = [];
            values.map(item => {
              item.data.map(item_ => {
                if (item_.isRead) {
                  arr.push(item_);
                }
                if (!item_.isRead) {
                  arr_.push(item_);
                }
              });
            });
            state.list = [...arr_, ...arr];
          }
        );
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      state.card = info.iccid;
      ctx.getData();
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
.message_box {
  height: 100vh;
  .both_content,
  .empty_content {
    width: 690px;
    background: #ffffff;
    border-radius: 20px;
    margin: 40px auto;
    overflow-y: auto;
    .desc_message {
      display: flex;
      justify-content: space-around;
      padding: 40px 40px 0px;
      .desc_logo img {
        width: 91px;
        height: 91px;
        position: relative;
        top: 4px;
      }
      .desc_text {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
        .desc_step {
          font: {
            size: 26px;
            weight: 400;
          }
          display: inline-block;
          width: 460px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #777777;
        }
      }
    }
  }
  .both_content {
    height: calc(100vh - 200px);
  }
  .empty_content {
    height: 200px;
  }
  .empty_list {
    font-size: 26px;
    color: #b2b2b2;
    padding: 80px;
    text-align: center;
  }
}
</style>
